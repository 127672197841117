import React, { useState } from 'react';
import { FaDollarSign, FaChair, FaWifi, FaBicycle } from 'react-icons/fa';

const CostBreakdownSection = () => {
    const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

    const costItems = [
        {
            icon: <FaDollarSign />,
            title: 'Rent',
            description: ' $100 to $300 per month depending on location.',
        },
        {
            icon: <FaChair />,
            title: 'Furniture & Setup',
            description: 'Study tables, chairs, racks, and LED lights for each ($3000 - $5000).',
        },
        {
            icon: <FaWifi />,
            title: 'Technology',
            description: 'Wi-Fi, EM door lock system, temperature sensors, fire alarms, and library management software ($100 - $200).',
        },
        {
            icon: <FaBicycle />,
            title: 'Parking',
            description: 'Cycle or bike parking ($50 - $100).',
        },
    ];

    return (
        <section style={styles.sectionWithImage}>
            <h2 style={styles.sectionTitle}>Costs Breakdown</h2>
            <div style={styles.costContainer}>
                <div style={styles.costCards}>
                    {costItems.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.card,
                                transform: hoveredCardIndex === index ? 'scale(1.05)' : 'scale(1)',
                                boxShadow: hoveredCardIndex === index ? '0px 8px 16px rgba(0, 0, 0, 0.2)' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                            onMouseEnter={() => setHoveredCardIndex(index)}
                            onMouseLeave={() => setHoveredCardIndex(null)}
                            onClick={() => setHoveredCardIndex(index)}
                        >
                            <div style={styles.icon}>{item.icon}</div>
                            <h3 style={styles.cardTitle}>{item.title}</h3>
                            {hoveredCardIndex === index && (
                                <p style={styles.cardDescription}>{item.description}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const styles = {
    sectionWithImage: {
        padding: '20px',
        backgroundColor: '#f4f4f9',
        textAlign: 'center',
    },
    sectionTitle: {
        fontSize: '2.5em',
        color: '#2c3e50',
        marginBottom: '30px',
    },
    costContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px',
    },
    costCards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        width: '250px',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        position: 'relative',
        cursor: 'pointer', // Add cursor pointer to indicate interactivity
    },
    icon: {
        fontSize: '2.5em',
        marginBottom: '10px',
        color: '#2980b9',
    },
    cardTitle: {
        fontSize: '1.5em',
        color: '#34495e',
        marginBottom: '10px',
    },
    cardDescription: {
        fontSize: '1.1em',
        color: '#7f8c8d',
        marginTop: '10px',
    },
    sectionImage: {
        width: '400px',
        height: '300px',
        objectFit: 'cover',
        marginLeft: '20px',
    },
};

export default CostBreakdownSection;
