import React, { useState } from 'react';


const currencies = [
    { code: 'INR', symbol: '₹', name: 'Indian Rupee' },
    { code: 'USD', symbol: '$', name: 'United States Dollar' },
    { code: 'EUR', symbol: '€', name: 'Euro' },
    { code: 'GBP', symbol: '£', name: 'British Pound Sterling' },
    { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
    { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
    { code: 'CAD', symbol: 'C$', name: 'Canadian Dollar' },
    { code: 'CHF', symbol: 'CHF', name: 'Swiss Franc' },
    { code: 'CNY', symbol: '¥', name: 'Chinese Yuan' },
    { code: 'SEK', symbol: 'kr', name: 'Swedish Krona' },
    { code: 'NZD', symbol: 'NZ$', name: 'New Zealand Dollar' },
    { code: 'MXN', symbol: '$', name: 'Mexican Peso' },
    { code: 'SGD', symbol: 'S$', name: 'Singapore Dollar' },
    { code: 'HKD', symbol: 'HK$', name: 'Hong Kong Dollar' },
    { code: 'NOK', symbol: 'kr', name: 'Norwegian Krone' },
    { code: 'KRW', symbol: '₩', name: 'South Korean Won' },
    { code: 'TRY', symbol: '₺', name: 'Turkish Lira' },

    { code: 'RUB', symbol: '₽', name: 'Russian Ruble' },
    { code: 'ZAR', symbol: 'R', name: 'South African Rand' },
    { code: 'BRL', symbol: 'R$', name: 'Brazilian Real' },
    { code: 'ARS', symbol: '$', name: 'Argentine Peso' },
    { code: 'CLP', symbol: '$', name: 'Chilean Peso' },
    { code: 'COP', symbol: '$', name: 'Colombian Peso' },
    { code: 'PEN', symbol: 'S/', name: 'Peruvian Sol' },
    { code: 'UYU', symbol: '$U', name: 'Uruguayan Peso' },
    { code: 'DZD', symbol: 'د.ج', name: 'Algerian Dinar' },
    { code: 'EGP', symbol: '£', name: 'Egyptian Pound' },
    { code: 'MAD', symbol: 'د.م.', name: 'Moroccan Dirham' },
    { code: 'TND', symbol: 'د.ت', name: 'Tunisian Dinar' },
    { code: 'KWD', symbol: 'د.ك', name: 'Kuwaiti Dinar' },
    { code: 'QAR', symbol: 'ر.ق', name: 'Qatari Rial' },
    { code: 'SAR', symbol: 'ر.س', name: 'Saudi Riyal' },
    { code: 'AED', symbol: 'د.إ', name: 'United Arab Emirates Dirham' },
    { code: 'BHD', symbol: 'د.ب', name: 'Bahraini Dinar' },
    { code: 'OMR', symbol: 'ر.ع.', name: 'Omani Rial' },
    { code: 'JOD', symbol: 'د.ا', name: 'Jordanian Dinar' },
    { code: 'LBP', symbol: 'ل.ل.', name: 'Lebanese Pound' },
    { code: 'SYP', symbol: 'ل.س', name: 'Syrian Pound' },
    { code: 'JMD', symbol: 'J$', name: 'Jamaican Dollar' },
    { code: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago Dollar' },
    { code: 'BBD', symbol: 'Bds$', name: 'Barbadian Dollar' },
    { code: 'KYD', symbol: 'KY$', name: 'Cayman Islands Dollar' },
    { code: 'BMD', symbol: 'B$', name: 'Bermudian Dollar' },
    { code: 'XCD', symbol: 'EC$', name: 'East Caribbean Dollar' },
    { code: 'GHS', symbol: 'GH₵', name: 'Ghanaian Cedi' },
    { code: 'TZS', symbol: 'TSh', name: 'Tanzanian Shilling' },
    { code: 'UGX', symbol: 'USh', name: 'Ugandan Shilling' },
    { code: 'RWF', symbol: 'FRw', name: 'Rwandan Franc' },
    { code: 'KES', symbol: 'KSh', name: 'Kenyan Shilling' },
    { code: 'BIF', symbol: 'FBu', name: 'Burundian Franc' },
    { code: 'MWK', symbol: 'MK', name: 'Malawian Kwacha' },
    { code: 'MZN', symbol: 'MT', name: 'Mozambican Metical' },
    { code: 'NAD', symbol: 'N$', name: 'Namibian Dollar' },
    { code: 'CVE', symbol: 'Esc', name: 'Cape Verdean Escudo' },
    { code: 'SLL', symbol: 'Le', name: 'Sierra Leonean Leone' },
    { code: 'LRD', symbol: 'L$', name: 'Liberian Dollar' },
    { code: 'GMD', symbol: 'D', name: 'Gambian Dalasi' },
    { code: 'DJF', symbol: 'Fdj', name: 'Djiboutian Franc' },
    { code: 'ERN', symbol: 'Nfk', name: 'Eritrean Nakfa' },
    { code: 'ETB', symbol: 'Br', name: 'Ethiopian Birr' },
    { code: 'SBD', symbol: 'SI$', name: 'Solomon Islands Dollar' },
    { code: 'PGK', symbol: 'K', name: 'Papua New Guinean Kina' },
    { code: 'FJD', symbol: 'FJ$', name: 'Fijian Dollar' },
    { code: 'WST', symbol: 'WS$', name: 'Samoan Tala' },
    { code: 'TVD', symbol: 'TV$', name: 'Tuvaluan Dollar' },
    { code: 'MOP', symbol: 'MOP$', name: 'Macanese Pataca' },
    { code: 'XPF', symbol: 'CFP', name: 'CFP Franc' },
    { code: 'KPW', symbol: '₩', name: 'North Korean Won' },
    { code: 'MMK', symbol: 'K', name: 'Burmese Kyat' },
    { code: 'LAK', symbol: '₭', name: 'Lao Kip' },
    { code: 'KHR', symbol: '៛', name: 'Cambodian Riel' },
    { code: 'MNT', symbol: '₮', name: 'Mongolian Tugrik' },
    { code: 'NPR', symbol: 'Rs', name: 'Nepalese Rupee' },
    { code: 'PKR', symbol: '₨', name: 'Pakistani Rupee' },
    { code: 'LKR', symbol: 'Rs', name: 'Sri Lankan Rupee' },
    { code: 'MDL', symbol: 'lei', name: 'Moldovan Leu' },
    { code: 'HUF', symbol: 'Ft', name: 'Hungarian Forint' },
    { code: 'CZK', symbol: 'Kč', name: 'Czech Koruna' },
    { code: 'PLN', symbol: 'zł', name: 'Polish Zloty' },
    { code: 'RON', symbol: 'lei', name: 'Romanian Leu' },
    { code: 'BGN', symbol: 'лв', name: 'Bulgarian Lev' },
    { code: 'HRK', symbol: 'kn', name: 'Croatian Kuna' },
    { code: 'ISK', symbol: 'kr', name: 'Icelandic Króna' },
    { code: 'DOP', symbol: 'RD$', name: 'Dominican Peso' },
    { code: 'PYG', symbol: '₲', name: 'Paraguayan Guarani' },
    { code: 'KZT', symbol: '₸', name: 'Kazakhstani Tenge' },
    { code: 'UZS', symbol: 'soʼm', name: 'Uzbekistani Som' },
    { code: 'MDL', symbol: 'lei', name: 'Moldovan Leu' },
    { code: 'SOS', symbol: 'S', name: 'Somali Shilling' },
    { code: 'BND', symbol: 'B$', name: 'Brunei Dollar' },
    { code: 'PAB', symbol: 'B/.', name: 'Panamanian Balboa' },
    { code: 'GIP', symbol: '£', name: 'Gibraltar Pound' },
    { code: 'XOF', symbol: 'CFA', name: 'West African CFA Franc' },
    { code: 'XAF', symbol: 'CFA', name: 'Central African CFA Franc' },
    { code: 'XAU', symbol: 'XAU', name: 'Gold' },
    { code: 'XAG', symbol: 'XAG', name: 'Silver' },
    { code: 'XPT', symbol: 'XPT', name: 'Platinum' },
    { code: 'XPD', symbol: 'XPD', name: 'Palladium' },
];



const PriceCalculator = () => {
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState({ name: '', quantity: 1, cost: 0 });
    const [extraColumns, setExtraColumns] = useState([]);
    const [currency, setCurrency] = useState(currencies[0]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewItem({ ...newItem, [name]: value });
    };

    const addItem = () => {
        if (!newItem.name || newItem.quantity <= 0 || newItem.cost < 0) {
            alert("Please fill out all fields with valid data.");
            return;
        }

        setItems([...items, { ...newItem, quantity: parseInt(newItem.quantity, 10), cost: parseFloat(newItem.cost) }]);
        setNewItem({ name: '', quantity: 1, cost: 0 });
    };

    const handleExtraColumnChange = (index, e) => {
        const { name, value } = e.target;
        const updatedExtraColumns = [...extraColumns];
        updatedExtraColumns[index] = { ...updatedExtraColumns[index], [name]: value };
        setExtraColumns(updatedExtraColumns);
    };

    const addExtraColumn = () => {
        setExtraColumns([...extraColumns, { name: '', quantity: 1, cost: 0 }]);
    };

    const totalCost = items.reduce((acc, item) => acc + (item.quantity * item.cost), 0);

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Price Calculator</h2>
            <div style={styles.form}>
                <h5>Item name</h5>
                <input
                    type="text"
                    name="name"
                    value={newItem.name}
                    onChange={handleChange}
                    placeholder="Accessory Name"
                    style={styles.input}
                />
                <h5>Quantiy</h5>
                <input
                    type="number"
                    name="quantity"
                    value={newItem.quantity}
                    onChange={handleChange}
                    placeholder="Quantity"
                    style={styles.input}
                />
                <h5>Cost per item</h5>
                <input
                    type="number"
                    name="cost"
                    value={newItem.cost}
                    onChange={handleChange}
                    placeholder="Cost per Item"
                    style={styles.input}
                />
                <button onClick={addItem} style={styles.button}>Add Item</button>
            </div>
            <div style={styles.currencySelector}>
                <label htmlFor="currency">Choose Currency: </label>
                <select
                    id="currency"
                    value={currency.code}
                    onChange={(e) => setCurrency(currencies.find(c => c.code === e.target.value))}
                    style={styles.select}
                >
                    {currencies.map(curr => (
                        <option key={curr.code} value={curr.code}>{curr.symbol} - {curr.code}</option>
                    ))}
                </select>
            </div>
            <table style={styles.table}>
                <thead style={styles.tableHead}>
                    <tr>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Cost per Item</th>
                        <th>Total Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                            <td>{item.name}</td>
                            <td>{item.quantity}</td>
                            <td>{currency.symbol}{item.cost.toFixed(2)}</td>
                            <td>{currency.symbol}{(item.quantity * item.cost).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h3 style={styles.totalCost}>Total Cost: {currency.symbol}{totalCost.toFixed(2)}</h3>
        </div>
    );
};

// Styles
const styles = {
    container: {
        padding: '20px',

        borderRadius: '8px',

        margin: 'auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '2em',
        color: '#333',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: '20px',
    },
    input: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '1em',
    },
    button: {
        padding: '10px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '1em',
        transition: 'background-color 0.3s ease',
    },
    buttonHover: {
        backgroundColor: '#0056b3',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        overflow: 'hidden',
    },
    tableHead: {
        backgroundColor: '#007bff',
        color: '#fff',
    },
    tableHeadCell: {
        padding: '10px',
        border: '1px solid #ddd',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    tableCell: {
        padding: '10px',
        border: '1px solid #ddd',
        textAlign: 'right',
    },
    evenRow: {
        backgroundColor: '#f9f9f9',
    },
    oddRow: {
        backgroundColor: '#fff',
    },
    extraColumns: {
        marginBottom: '20px',
    },
    extraColumn: {
        display: 'flex',
        gap: '10px',
        marginBottom: '10px',
    },
    currencySelector: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    select: {
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '1em',
    },
    totalCost: {
        textAlign: 'center',
        fontSize: '1.5em',
        color: '#333',
    },
};

export default PriceCalculator;
