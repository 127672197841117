import React from 'react';
import ReactPlayer from 'react-player/youtube';
import './VideoCard.css';

const VideoCard = ({ url }) => {
    return (
        <div className="video-card">
            <ReactPlayer url={url} className="react-player" />
        </div>
    );
}

export default VideoCard;
