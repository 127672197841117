import React, { useState } from 'react';
import { FaMapMarkerAlt, FaTools, FaDollarSign } from 'react-icons/fa';

const StepByStepGuide = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const steps = [
        {
            icon: <FaMapMarkerAlt />,
            title: 'Research & Planning',
            description: 'Identify the demand for a self-study library in your area, gather data, and plan the setup.',
        },
        {
            icon: <FaTools />,
            title: 'Location & Setup',
            description: 'Choose a location that’s accessible and quiet. Ensure it has enough space for desks, study racks, and individual lights.',
        },
        {
            icon: <FaDollarSign />,
            title: 'Budgeting & Costs',
            description: 'Prepare a budget, including rent, utilities, furniture, and monthly operational costs.',
        },
    ];

    return (
        <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Step-by-Step Guide</h2>
            <div style={styles.stepsGrid}>
                {steps.map((step, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.stepCard,
                            transform: hoveredIndex === index ? 'translateY(-5px)' : 'translateY(0)',
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={() => setHoveredIndex(hoveredIndex === index ? null : index)} // Toggle on click for touch devices
                    >
                        <div style={styles.iconContainer}>
                            {step.icon}
                        </div>
                        <h3 style={styles.stepTitle}>{step.title}</h3>
                        {hoveredIndex === index && (
                            <p style={styles.stepDescription}>{step.description}</p>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

const styles = {
    section: {
        padding: '50px 20px',
        backgroundColor: '#f9f9f9',
    },
    sectionTitle: {
        fontSize: '2.5em',
        textAlign: 'center',
        color: '#2c3e50',
        marginBottom: '40px',
    },
    stepsGrid: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        flexWrap: 'wrap',
    },
    stepCard: {
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        textAlign: 'center',
        width: '280px',
        transition: 'transform 0.3s ease',
        cursor: 'pointer',
        position: 'relative', // Ensure position relative for absolute positioning of description
    },
    iconContainer: {
        fontSize: '2.5em',
        color: '#3498db',
        marginBottom: '15px',
    },
    stepTitle: {
        fontSize: '1.5em',
        color: '#34495e',
        marginBottom: '10px',
    },
    stepDescription: {
        fontSize: '1.1em',
        color: '#7f8c8d',
        marginTop: '10px',
    },
};

export default StepByStepGuide;
