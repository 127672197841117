
import React from 'react';
import VideoCard from './Blog/video-cards';
import './Blog.css';

export default function Blog() {
    return (
        <div>
            <div className="vdo-blog">
                <h2>Introduction</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/v-RjAiazf-c?si=srG5vBW0ZHk4UHWA" />
                </div>
                <h2>How to install Manager Web APP in PC | Laptop | Tablet | Mobile</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/lKTSZl0uTRM?si=s_oFYSqtuWZrJ4c2" />
                </div>
                <h2>25+ Features of managelibs version 2.1.0</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/7a4RCswc3Wc?si=1AyWbptllr3Sou4_" />
                </div>

                <h2>How to register new aspirant to manager app (version 4.0) ?</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/fv6gxrLawqk?si=XW1Ebwd0lhitzzVK" />
                </div>

                <h2>Order your website before June 10, 2024 and get upto 70% off</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/oEaoTWZ7djo?si=lH3xV4xV7ogRHdOi" />
                </div>
                <h2>How to setup manager app?</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/GC_MtVziN-w?si=NkpSbzqFpVpwGwS4" />
                </div>

                <h2>How to setup aspirant app?</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/1IEAxNiqDzk?si=bcZrUMP7M36Jovvh" />
                </div>

                <h2>How to register new aspirant and book seat?</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtu.be/wnVFG8SwumM?si=C0VTwf_uWgZUt7Ji" />
                </div>
                <h2>OpenAI in Aspirant APP</h2>
                <div className="vdo-blog-container">
                    <VideoCard url="https://youtube.com/shorts/Ana4lpVRlWo?si=7L5WYCx6-wSs88_n" />
                </div>
            </div>
            <div className="blog">
                <div className="blog-container">
                    <img src="assets/contactIcons/blogg.png" alt="Blog" />
                </div>
                <h2>Stay Connected For Future Blogs</h2>

            </div>


        </div>
    );
}
