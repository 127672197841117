import React, { useState } from 'react';
import { FaChair, FaLightbulb, FaWifi, FaBook, FaFan, FaLock, FaThermometerHalf } from 'react-icons/fa';

const ComponentsSection = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const components = [
        {
            icon: <FaChair />,
            title: 'Study Desks & Chairs',
            description: 'Comfortable chairs with shoe racks for organized study stations.',
        },
        {
            icon: <FaLightbulb />,
            title: 'LED Lights',
            description: 'Bright LED light for each study station.',
        },
        {
            icon: <FaWifi />,
            title: 'Wi-Fi & Computers',
            description: 'Wi-Fi access and computers for research and study.',
        },
        {
            icon: <FaBook />,
            title: 'Books & Materials',
            description: 'Common books, magazines, and study materials available.',
        },
        {
            icon: <FaFan />,
            title: 'Fans & AC',
            description: 'Fans, heaters, exhausters, and AC to provide comfort in all seasons.',
        },
        {
            icon: <FaLock />,
            title: 'Smart Door Lock System',
            description: 'Secure entry system with smart locks for safety.',
        },
        {
            icon: <FaThermometerHalf />,
            title: 'Temperature & Fire Sensors',
            description: 'Safety equipment including temperature and fire sensors.',
        },
    ];

    return (
        <section style={styles.sectionWithImageReversed}>
            <h2 style={styles.sectionTitle}>Components & Equipment</h2>
            <div style={styles.componentsContainer}>
                <div style={styles.componentCards}>
                    {components.map((component, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.card,
                                transform: hoveredIndex === index ? 'translateY(-5px)' : 'translateY(0)',
                                boxShadow: hoveredIndex === index ? '0px 8px 16px rgba(0, 0, 0, 0.2)' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <div style={styles.icon}>
                                {component.icon}
                            </div>
                            <h3 style={styles.cardTitle}>{component.title}</h3>
                            {hoveredIndex === index && (
                                <p style={styles.cardDescription}>{component.description}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const styles = {
    sectionWithImageReversed: {
        padding: '20px',
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
    },
    sectionTitle: {
        fontSize: '2.5em',
        color: '#2c3e50',
        marginBottom: '30px',
    },
    componentsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px',
    },
    componentCards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        width: '250px',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        position: 'relative', // Ensure position relative for absolute positioning of description
    },
    icon: {
        fontSize: '2.5em',
        marginBottom: '10px',
        color: '#2980b9',
    },
    cardTitle: {
        fontSize: '1.5em',
        color: '#34495e',
        marginBottom: '10px',
    },
    cardDescription: {
        fontSize: '1.1em',
        color: '#7f8c8d',
        marginTop: '10px',
    },
};

export default ComponentsSection;
