import React from "react";



export default function MicrosoftStartups() {
    return (
        <div className="ServiceCards">
            {/* // put image too */}
            <div>our partner - </div>
            <div>Microsoft for startups founders Hub</div>
        </div>
    );
}