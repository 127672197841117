import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import './AccessService.css';

export default function ActionAreaCard({ LogoUrl, ServiceName, ServiceDescription, LinkUrl, Suitability }) {
    const handleClick = (event) => {
        window.open(LinkUrl, '_blank');
    };

    return (
        <div className='cardMain'>
            <Card sx={{ maxWidth: 250 }} className='card'>
                <div className="suitability-label">{Suitability}</div> {/* Suitability label */}
                <CardActionArea className='CardActionArea' onClick={handleClick}>
                    <Typography className='center' gutterBottom variant="h5" component="div" color="text.secondary">
                        {ServiceName}
                    </Typography>
                    <CardMedia
                        className='logo-center'
                        component="img"
                        image={LogoUrl}
                        alt={`${ServiceName} Logo`}
                    />
                    <CardContent className='center'>
                        <Typography variant="body2" color="text.secondary">
                            {ServiceDescription}
                        </Typography>
                    </CardContent>
                    <button className='btn center'>view more</button>
                </CardActionArea>
            </Card>
        </div>
    );
}
