import React from "react";
import "./Contact.css"


export default function Contacts() {
    return (

        <div className="Contact-container"> {/* Use a class for styling */}

            <div className="contact-container-1">
                <img src="assets/contactIcons/message.png" />
            </div>
            <div className="contact-container-1">
                <ul>
                    <h2>Contact Us</h2>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/contactIcons/support-services.png" /></div>
                        <div className="emailDiv">
                            <h5>Support-Email</h5>
                            <p>help@managelibs.com</p>
                        </div>
                    </li>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/contactIcons/email.png" /></div>
                        <div className="emailDiv">
                            <h5>Developer-Email</h5>
                            <p>developer@managelibs.com</p>
                        </div>
                    </li>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/contactIcons/mail.png" /></div>
                        <div className="emailDiv">
                            <h5>Society of Humanity Education and Research</h5>
                            <p>sher@managelibs.com</p>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    );
}
