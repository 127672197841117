import React from "react";
import "./Service.css"
// import BusinessForm from './BusinessForm/businessForm.js';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Star } from "@mui/icons-material";
import VideoCard from "./Blog/video-cards";
export default function Service() {
    return (
        <section>

            <div className="vdo-blog-container">
                <VideoCard url="https://youtu.be/oEaoTWZ7djo?si=lH3xV4xV7ogRHdOi" />
            </div>
            <WebService />
            <div className="service-container"> {/* Use a class for styling */}

                <div className="service-container-1 hoverEffect">
                    <img src="assets/serviceIcons/serviceImg.png" />
                </div>
                <div className="service-container-1">
                    <ul>
                        <h2>What We Offer</h2>
                        <li className="email-li hoverEffect">
                            <div className="emailDiv"><img src="assets/serviceIcons/web.png" /></div>
                            <div className="emailDiv">
                                <h5>Web services</h5>
                                <p>Unlock your digital potential with our innovative web services</p>
                            </div>
                        </li>
                        <li className="email-li hoverEffect">
                            <div className="emailDiv"><img src="assets/serviceIcons/app.png" /></div>
                            <div className="emailDiv">
                                <h5>App services</h5>
                                <p>Unleash your digital potential with our application services</p>
                            </div>
                        </li>
                        <li className="email-li hoverEffect">
                            <div className="emailDiv"><img src="assets/serviceIcons/mail.png" /></div>
                            <div className="emailDiv">
                                <h5>Society of Humanity Education and Research</h5>
                                <p>help@managelibs.com</p>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>
            {/* <BusinessForm/> */}
        </section>
    );
}




function WebService() {
    const handleButtonClick = () => {
        alert('Application is closed now!');
        // window.location.href = 'https://forms.gle/9z4aofD29LkDJCLj9';
    };
    const handleButtonInfo = () => {
        alert('To become libray partner, you have to use our Library Management Services');
    };
    return (

        <div className="service-container"> {/* Use a class for styling */}

            <div className="service-container-1 hoverEffect">
                <img src="assets/Offer/70percentoff.png" />
            </div>
            <div className="service-container-1">
                <ul>
                    <div className="text-center">
                        <h2>Order Your Library Website</h2>
                        <h4>Application is Closed Now</h4>
                        <h4>Application is Ending at Sept 10, 2024</h4>
                    </div>
                    <div className="button-container">
                        <Button variant="contained" endIcon={<SendIcon />} onClick={handleButtonClick}>
                            Fill Form
                        </Button>
                    </div>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/serviceIcons/web.png" /></div>
                        <div className="emailDiv">
                            <h5>Digitalization</h5>
                            <p>Make your library completely digital</p>
                        </div>
                    </li>

                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/Offer/70PercentOff.jpeg" /></div>
                        <div className="emailDiv">
                            <h5>70% Off (1999 ₹ <del>6664</del>)</h5>
                            <p>for first 10 partner library</p>
                        </div>
                    </li>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/Offer/55percentoff.jpeg" /></div>
                        <div className="emailDiv">
                            <h5>55% Off (2999 ₹ <del>6664</del>)</h5>
                            <p>for next 15 partner library</p>
                        </div>
                    </li>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/Offer/40percentoff.jpeg" /></div>
                        <div className="emailDiv">
                            <h5>40% Off (3999 ₹ <del>6664</del>)</h5>
                            <p>for next 20 partner library</p>
                        </div>
                    </li>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/Offer/partner.png" /></div>
                        <div className="emailDiv">
                            {/* <div className="button-container"> */}

                            <Button variant="outline" endIcon={<Star />} onClick={handleButtonInfo}>
                                <h5>How to become partner</h5>
                            </Button>
                            {/* </div> */}
                            <p>use our library management services</p>
                        </div>

                    </li>
                    <li className="email-li hoverEffect">
                        <div className="emailDiv"><img src="assets/serviceIcons/mail.png" /></div>
                        <div className="emailDiv">
                            <h5>Any query? Contact us</h5>
                            <p>help@managelibs.com </p>
                            <p>+91 6388257447</p>
                        </div>
                    </li>
                </ul>
            </div>

        </div>

    );
}
