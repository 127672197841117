import React, { useState } from "react";
import { FaMapMarkerAlt, FaUniversity, FaChartLine, FaUserGraduate, FaBuilding } from "react-icons/fa";
import { GiRapidshareArrow } from "react-icons/gi";
import { MdLibraryAdd } from "react-icons/md";

const LibraryExpansionFeatures = () => {
    const [hoveredFeatureIndex, setHoveredFeatureIndex] = useState(null);

    const features = [
        {
            icon: <FaMapMarkerAlt />,
            title: "Widespread Presence",
            description: "Over 15,000+ libraries are operational in North India with rapid growth across states like Tamil Nadu, Andhra Pradesh, Mumbai, Odisha, West Bengal, and more.",
        },
        {
            icon: <FaBuilding />,
            title: "Top Locations",
            description: "Key states such as Uttar Pradesh (3,500+), Bihar (2,500+), Delhi (5,000+), Madhya Pradesh (4,000+), and Haryana (2,000+) are leading the growth.",
        },
        {
            icon: <GiRapidshareArrow />,
            title: "Rapid Expansion",
            description: "Every day, more than 5 new libraries are opening across India, creating a significant business opportunity.",
        },
        {
            icon: <FaUserGraduate />,
            title: "Student-Centric",
            description: "Students are flocking to these libraries for a peaceful, quiet, comfortable, competitive, and learning environment.",
        },
        {
            icon: <FaChartLine />,
            title: "Growing Trend",
            description: "The self-study library trend is rapidly spreading, especially in backward regions, providing untapped potential for growth.",
        },
        {
            icon: <MdLibraryAdd />,
            title: "Business Potential",
            description: "A promising business opportunity for developing countries, with room for expansion and innovation in various regions.",
        },
    ];

    return (
        <div style={styles.container}>
            <h2 style={styles.sectionTitle}>Library Business Expansion Features</h2>
            <div style={styles.featuresContainer}>
                {features.map((feature, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.featureItem,
                            backgroundColor: hoveredFeatureIndex === index ? '#e0e0e0' : '#fff', // Change background on hover
                        }}
                        onMouseEnter={() => setHoveredFeatureIndex(index)}
                        onMouseLeave={() => setHoveredFeatureIndex(null)}
                    >
                        <div style={styles.icon}>{feature.icon}</div>
                        <h3 style={styles.title}>{feature.title}</h3>
                        <p
                            style={{
                                ...styles.description,
                                maxHeight: hoveredFeatureIndex === index ? '100px' : '0', // Adjust height based on hover state
                                opacity: hoveredFeatureIndex === index ? 1 : 0,
                                transition: 'max-height 0.3s ease, opacity 0.3s ease',
                                overflow: 'hidden',
                            }}
                        >
                            {feature.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Styles
const styles = {
    container: {
        padding: "20px",
        backgroundColor: "#f4f4f9",
    },
    sectionTitle: {
        fontSize: '2.5em',
        color: '#2c3e50',
        marginBottom: '30px',
        textAlign: 'center',
    },
    featuresContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    featureItem: {
        width: "100%",
        maxWidth: "300px",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
        cursor: "pointer",
        transition: 'background-color 0.3s ease',
        position: "relative",
    },
    icon: {
        fontSize: "2em",
        marginBottom: "10px",
        color: "#2980b9",
    },
    title: {
        fontSize: "1.5em",
        color: "#34495e",
        marginBottom: "10px",
    },
    description: {
        fontSize: "1.1em",
        color: "#7f8c8d",
        lineHeight: "1.4em",
        maxHeight: '0',
        opacity: 0,
        overflow: 'hidden',
        transition: 'max-height 0.3s ease, opacity 0.3s ease',
    },
};

export default LibraryExpansionFeatures;
