import React, { useState } from 'react';
import { FaUsers, FaDollarSign } from 'react-icons/fa';

const SuccessEarningsSection = () => {
    const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

    const earningsFeatures = [
        {
            icon: <FaDollarSign />,
            title: 'Membership Income',
            description: 'Generate steady income from student memberships with various pricing options.',
        },
        {
            icon: <FaDollarSign />,
            title: 'Additional Services',
            description: 'Earn extra revenue by offering services like snacks, printing, and study-related conveniences.',
        },
    ];

    return (
        <section style={styles.successSection}>
            <h2 style={styles.sectionTitle}>Library Success & Earnings</h2>
            <div style={styles.successContent}>
                <div style={styles.iconContainer}>
                    <FaUsers style={styles.bigIcon} />
                </div>
                <p style={styles.successText}>
                    Your library will become a thriving hub for students eager to join. With the right setup, you can generate steady membership income and additional revenue from services like snacks, printing, and other conveniences.
                </p>
                <div style={styles.earningsCards}>
                    {earningsFeatures.map((feature, index) => (
                        <div
                            key={index}
                            style={styles.card}
                            onMouseEnter={() => setHoveredCardIndex(index)}
                            onMouseLeave={() => setHoveredCardIndex(null)}
                            onClick={() => setHoveredCardIndex(index === hoveredCardIndex ? null : index)}
                        >
                            <div style={styles.icon}>{feature.icon}</div>
                            <h3 style={styles.cardTitle}>{feature.title}</h3>
                            <p
                                style={{
                                    ...styles.cardDescription,
                                    maxHeight: hoveredCardIndex === index ? '100px' : '0', // Adjust height based on hover state
                                    opacity: hoveredCardIndex === index ? 1 : 0,
                                    transition: 'max-height 0.3s ease, opacity 0.3s ease',
                                    overflow: 'hidden',
                                }}
                            >
                                {feature.description}
                            </p>
                        </div>
                    ))}
                </div>
                {/* <img src="https://via.placeholder.com/400x300" alt="Library Success" style={styles.sectionImage} /> */}
            </div>
        </section>
    );
};

// Styles
const styles = {
    successSection: {
        padding: '40px 20px',
        backgroundColor: '#f8f8f8',
    },
    sectionTitle: {
        fontSize: '2.5em',
        color: '#2c3e50',
        textAlign: 'center',
        marginBottom: '30px',
    },
    successContent: {
        maxWidth: '1200px',
        margin: '0 auto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconContainer: {
        marginBottom: '20px',
    },
    bigIcon: {
        fontSize: '4em',
        color: '#3498db',
    },
    successText: {
        fontSize: '1.2em',
        marginBottom: '30px',
        color: '#7f8c8d',
        maxWidth: '700px',
    },
    earningsCards: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        flexWrap: 'wrap',
        marginTop: '20px',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        width: '250px',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        cursor: 'pointer',
        position: 'relative',
    },
    icon: {
        fontSize: '2.5em',
        marginBottom: '10px',
        color: '#e74c3c',
    },
    cardTitle: {
        fontSize: '1.5em',
        color: '#34495e',
        marginBottom: '10px',
    },
    cardDescription: {
        fontSize: '1.1em',
        color: '#7f8c8d',
        lineHeight: '1.4em',
        maxHeight: '0', // Initial height for non-hover state
        opacity: 0,
        overflow: 'hidden',
        transition: 'max-height 0.3s ease, opacity 0.3s ease',
    },
    sectionImage: {
        marginTop: '30px',
        maxWidth: '100%',
        borderRadius: '10px',
    },
};

export default SuccessEarningsSection;
