// src/App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import BottomBar from './components/BottomNavbar';
import Home from './components/Body/Home';
import Career from './components/Body/Career';
import Contacts from './components/Body/Contact';
import Blog from './components/Body/Blog';
import Service from './components/Body/Service';
import About from './components/Body/AboutUs';
import FloatingMessageBox from './components/FloatingMessage';
import LibraryGuideline from './components/Body/NewLibrary/NewLibrary';



const HomeComponents = () => <Home />;
const ServiceComponents = () => <Service />;
const BlogComponents = () => <Blog />;
const CareerComponents = () => <Career />;
const ContactComponents = () => <Contacts />;
const aboutComponents = () => <About />
const newLibraryComponents = () => <LibraryGuideline />






const App = () => {

  return (
    <>

      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" exact element={HomeComponents()} />
          <Route path="/openlibraryguide"  element={newLibraryComponents()} />
          <Route path="/service" element={ServiceComponents()} />
          <Route path="/career" element={CareerComponents()} />
          <Route path="/contact" element={ContactComponents()} />
          <Route path="/blog" element={BlogComponents()} />
          <Route path="/about" element={aboutComponents()} />

        </Routes>
        {/* floating message box on click it popup contact email to email and mobile number to call */}
        <FloatingMessageBox />
      </BrowserRouter>
      <BottomBar />

    </>

  );
};

export default App;