import React from "react";
import './Career.css'


export default function Career() {
    return (
        <div className="Career">
        <div className="Career-container"> {/* Use a class for styling */}
              <img src="assets/contactIcons/career.png"/>      
        </div>
        <h2>Stay Connected For Future Update</h2>
        </div>
    );
}
