import React from "react";
import ActionAreaCard from "./Service/ServiceTemplate/AccessService";
import './Service.css'
import ManageLibsServices from "./Service/ManageLibs";


const serviceData = [
    // {
    //     LogoUrl: "/assets/OpenScienceLogo2.png",
    //     ServiceName: "OpenScience",
    //     ServiceDescription: "Providing facilities to educational institutions and students to grow and enhance innovation culture    with the help of local businesses. - UPCOMING..."
    // },
    {
        LogoUrl: "/assets/mangIcons/managelibicon.png",
        ServiceName: "Manager APP",
        ServiceDescription:
            "Library Management Software for library manager (version 4.0.1) - Recommended",
        LinkUrl: "https://managerwebapp-hjbye7czhtgbczg8.centralindia-01.azurewebsites.net/",
        Suitability: "DESKTOP"
    },

    {
        LogoUrl: "/assets/mangIcons/managelibicon.png",
        ServiceName: "Aspirant APP",
        ServiceDescription:
            "Library Management Software for library aspirant (version 4.0.0) - FREE",
        LinkUrl: "https://play.google.com/store/apps/details?id=com.managelibs.aspirantapp&pcampaignid=web_share",
        Suitability: "Android"
    },
    {
        LogoUrl: "/assets/mangIcons/managelibicon.png",
        ServiceName: "Data Privacy & Terms",
        ServiceDescription:
            "Clear and transparent data privacy & terms for libraries. last updated 08-09-2024",
        LinkUrl: "https://github.com/S-H-E-R-Development/Privacy-Policy-/blob/main/Privacy%20%2C%20Payment%20Information%20and%20Refund%20Policy%2C%20Terms%20and%20Conditions%20-%20Manager%20APP.md",
        Suitability: "Security"
    },
    {
        LogoUrl: "/assets/mangIcons/managelibicon.png",
        ServiceName: "Manager APP",
        ServiceDescription:
            "Library Management Software for library manager (version 4.0.1)",
        LinkUrl: "https://drive.google.com/file/d/1hQkr761nT_ltqnTABxW5n2ped6E22Ryj/view?usp=sharing",
        Suitability: "Android"
    },
    {
        LogoUrl: "/assets/icon-512.png",
        ServiceName: "TOP108",
        ServiceDescription:
            "Internship platform to show opportunity update from top 108 companies - OPEN SOURCE ",
        LinkUrl: "https://top108.web.app",
        Suitability: "ALL"
    },

    {
        LogoUrl: "/assets/SHERappLogo.png",
        ServiceName: "Coaching App",
        ServiceDescription: "Sample coaching app, containing material sharing, info. notification, guidance etc.. - Order Your APP",
        LinkUrl: "https://play.google.com/store/apps/details?id=com.rkverma.sherapp",
        Suitability: "ANDROID"
    },

    // {
    //     LogoUrl: "/assets/mangIcons/ncSwitch.png",
    //     ServiceName: "ncSwitch",
    //     ServiceDescription:
    //         "YouTube, Instagram, Facebook, Telegram etc. on single page -  UPCOMING...",
    //     LinkUrl: ""
    //     , Suitability: "ANDROID"
    // },

];


const classroomServiceData = [
    // {
    //     LogoUrl: "/assets/OpenScienceLogo2.png",
    //     ServiceName: "OpenScience",
    //     ServiceDescription: "Providing facilities to educational institutions and students to grow and enhance innovation culture    with the help of local businesses. - UPCOMING..."
    // },

    {
        LogoUrl: "/assets/ClassroomLogo.png",
        ServiceName: "Admin APP",
        ServiceDescription:
            "For school or coaching adminstrations to manage students and faculty (v1.0.0) - ₹ 0 - android",
        LinkUrl: "https://drive.google.com/file/d/1pO9gri9eJFGzTGRhDofHlt-MjNfz5XhG/view?usp=drive_link",
        Suitability: "Android"
    },
    {
        LogoUrl: "/assets/ClassroomLogo.png",
        ServiceName: "Faculty APP",
        ServiceDescription:
            "For faculty to create courses and take manually or QR based attendance of students (v1.0.0) - ₹ 0 - android",
        LinkUrl: "https://drive.google.com/file/d/1fU1fE9Nrc6SguknKiXfZIzRVUnzazOEx/view?usp=drive_link", Suitability: "Android"
    },
    {
        LogoUrl: "/assets/ClassroomLogo.png",
        ServiceName: "Student APP",
        ServiceDescription:
            "For student to give attendance and see course time table (v1.0.0) - FREE - android",
        LinkUrl: "https://play.google.com/store/apps/details?id=com.advclassroom.student&pcampaignid=web_share", Suitability: "Android"
    },
    {
        LogoUrl: "/assets/ClassroomLogo.png",
        ServiceName: "Privacy Policy",
        ServiceDescription:
            "Adv Classroom Lite, Privacy Policy - last updated 02-09-2024",
        LinkUrl: "https://github.com/S-H-E-R-Development/Privacy-Policy-/blob/main/Advanced%20Classroom%20Lite.md", Suitability: "Security"
    },
];
export default function Service() {
    return (
        <>
            <div className="servicesDiv">
                <p className="servicesHeading">Our Services In Market</p>
                <div className="ServiceCardss">
                    {serviceData.map((service, index) => (
                        <ActionAreaCard
                            key={index}
                            LogoUrl={service.LogoUrl}
                            ServiceName={service.ServiceName}
                            ServiceDescription={service.ServiceDescription}
                            LinkUrl={service.LinkUrl}
                            Suitability={service.Suitability}
                        />
                    ))}
                </div>
            </div>
            <div className="servicesDiv">
                <p className="servicesHeading">Adv. Classroom Lite (Free)</p>
                <div className="ServiceCardss">
                    {classroomServiceData.map((service, index) => (
                        <ActionAreaCard
                            key={index}
                            LogoUrl={service.LogoUrl}
                            ServiceName={service.ServiceName}
                            ServiceDescription={service.ServiceDescription}
                            LinkUrl={service.LinkUrl}
                            Suitability={service.Suitability}
                        />
                    ))}
                </div>
            </div>
            <ManageLibsServices />

        </>
    );
}
