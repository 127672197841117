import React from "react";
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Partnership from "./BottomNavbar/Partnership";
let socialMediaLinks = { 'Twitter': 'https://x.com/Rajkuma51641747', 'YouTube': 'https://www.youtube.com/watch?v=1IEAxNiqDzk&list=PLU0MBk-u7HQmI5o9i4JTRv0Rox1mVry63', 'LinkedIn': 'https://www.linkedin.com/in/rajkumar-verma-353966222/', 'Facebook': 'https://www.facebook.com/people/Society-of-Humanity-Education-and-Research/61559028389595/', 'Instagram': 'https://www.instagram.com/rajkumarverma70790/?next=%2F&hl=en' };

function IconButton({ name, link }) {
  let icon;
  switch (name) {
    case 'Twitter':
      icon = <FontAwesomeIcon icon={faTwitter} />;
      break;
    case 'YouTube':
      icon = <FontAwesomeIcon icon={faYoutube} />;
      break;
    case 'LinkedIn':
      icon = <FontAwesomeIcon icon={faLinkedin} />;
      break;
    case 'Facebook':
      icon = <FontAwesomeIcon icon={faFacebook} />;
      break;
    case 'Instagram':
      icon = <FontAwesomeIcon icon={faInstagram} />;
      break;
    default:
      icon = null;
  }
  return (
    <div className="social-links">
      <a href={link} >
        {icon}
      </a>
    </div>
  );
}



export default function BottomBar() {
  return (


    <footer >
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-col">
              <h4>SHER</h4>
              <ul>
                <li className="footerHeading">
                  Society of Humanity Education and Research,
                  Dedicated to solve real life problems to empower Humanity, Education and Research</li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Partnership</h4>
              <ul className="partner">
                <Partnership />
              </ul>
            </div>
            <div className="footer-col links">
              <h4>Links</h4>
              <ul>
                <li>Home</li>
                <li>Service</li>
                <li>Blog</li>
                <li>Career</li>
                <li>Contact</li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>follow us</h4>
              <div className="social-link-div">
                {Object.entries(socialMediaLinks).map(([name, link]) => (
                  <IconButton key={name} name={name} link={link} />
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 className="copyright">Copyright © 2024 Society of Humanity Education and Research. All rights reserved.</h3>

    </footer >
  );
}
