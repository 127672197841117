import React, { useState } from "react";
import { FaGlobe, FaArrowRight } from "react-icons/fa";
import { GiChart, GiWorld } from "react-icons/gi";

const FutureGrowthSection = () => {
    const [hoveredFeatureIndex, setHoveredFeatureIndex] = useState(null);

    const growthFeatures = [
        {
            icon: <GiWorld />,
            title: "Global Expansion",
            description: "The success of self-study libraries in India is expected to expand beyond borders to countries such as Bangladesh, Nepal, Sri Lanka, Pakistan, Bhutan, and others.",
        },
        {
            icon: <GiChart />,
            title: "Potential in Developing Nations",
            description: "This business model is particularly suited for developing nations where students are looking for structured, peaceful environments to study and excel academically.",
        },
        {
            icon: <FaArrowRight />,
            title: "Rising Demand",
            description: "As demand continues to grow, self-study libraries are set to become a major business opportunity in the region, reflecting their increasing relevance and popularity.",
        },
    ];

    return (
        <section style={styles.globalExpansionSection}>
            <h2 style={styles.sectionTitle}>Future Growth & Global Potential</h2>
            <div style={styles.globalExpansionContent}>
                {growthFeatures.map((feature, index) => (
                    <div
                        key={index}
                        style={styles.featureItem}
                        onMouseEnter={() => setHoveredFeatureIndex(index)}
                        onMouseLeave={() => setHoveredFeatureIndex(null)}
                        onClick={() => setHoveredFeatureIndex(hoveredFeatureIndex === index ? null : index)} // Toggle on click
                    >
                        <div style={styles.icon}>{feature.icon}</div>
                        <h3 style={styles.featureTitle}>{feature.title}</h3>
                        <p
                            style={{
                                ...styles.featureDescription,
                                maxHeight: hoveredFeatureIndex === index ? 'none' : '2.4em',
                                transition: 'max-height 0.3s ease, opacity 0.3s ease',
                                opacity: hoveredFeatureIndex === index ? 1 : 0,
                            }}
                        >
                            {feature.description}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
};

const styles = {
    globalExpansionSection: {
        padding: "20px",
        backgroundColor: "#f4f4f9",
        textAlign: "center",
    },
    sectionTitle: {
        fontSize: "2.5em",
        color: "#2c3e50",
        marginBottom: "30px",
    },
    globalExpansionContent: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "20px",
    },
    featureItem: {
        width: "100%",
        maxWidth: "300px",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        cursor: "pointer",
        position: "relative",
    },
    icon: {
        fontSize: "2.5em",
        marginBottom: "10px",
        color: "#2980b9",
    },
    featureTitle: {
        fontSize: "1.5em",
        color: "#34495e",
        marginBottom: "10px",
    },
    featureDescription: {
        fontSize: "1.1em",
        color: "#7f8c8d",
        padding: "0 10px",
        maxHeight: '2.4em',
        overflow: 'hidden',
        transition: 'max-height 0.3s ease, opacity 0.3s ease',
        opacity: 0,
    },
};

export default FutureGrowthSection;
