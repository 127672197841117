import React from "react";

import LibraryExpansionFeatures from "./libraryExpansionFeature";
import FutureGrowthSection from "./FutureGrowth";
import CostBreakdownSection from "./CostBreakDown";
import ComponentsSection from "./ComponentsAndEquipments";
import SafetyFeaturesSection from "./SafetyAndEmergency";
import SuccessEarningsSection from "./LibrarySuccessAndEarning";
import StepByStepGuide from "./StepByStepGuide";
import PriceCalculator from "./Calculator";
import { Button } from "@mui/material";

export default function LibraryGuideline() {
    const handleButtonClickGform = () => {
        // alert('Application is opening...');
        window.location.href = 'https://forms.gle/8oo65LEmvxaFdpSg8';
    };
    return (
        <section style={styles.container}>
            {/* Header Section */}
            <header style={styles.header}>
                <h1 style={styles.title}>How to Open a Self-Study Library</h1>
                <p style={styles.introText}>Discover the booming business of self-study libraries and how you can join this growing trend.</p>
            </header>

            {/* Step-by-Step Guide */}
            <StepByStepGuide />

            {/* Library Expansion Features */}
            <LibraryExpansionFeatures />

            {/* Future Growth Section */}
            <FutureGrowthSection />

            {/* Cost Breakdown Section */}
            <CostBreakdownSection />

            {/* Components and Equipments Section */}
            <ComponentsSection />

            {/* Safety Features Section */}
            <SafetyFeaturesSection />

            {/* Success and Earnings Section */}
            <SuccessEarningsSection />

            {/* opening library calculator */}
            <PriceCalculator />

            <div className="button-container">
                <Button variant="contained" onClick={handleButtonClickGform}>
                    Book 5 min conversession for free
                </Button>
            </div>
        </section>
    );
}

// Styles
const styles = {
    container: {
        fontFamily: "'Roboto', sans-serif",
        maxWidth: "1300px",
        margin: "0 auto",
        padding: "20px",
        color: "#333",
        lineHeight: "1.6",
    },
    header: {
        textAlign: "center",
        marginBottom: "40px",
        backgroundColor: "#f7f7f9",
        padding: "40px",
        borderRadius: "10px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    },
    title: {
        fontSize: "2.8em",
        color: "#2c3e50",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    introText: {
        fontSize: "1.2em",
        color: "#7f8c8d",
        maxWidth: "800px",
        margin: "0 auto",
    },
    section: {
        marginBottom: "50px",
    },
    sectionTitle: {
        fontSize: "2.2em",
        color: "#2c3e50",
        marginBottom: "20px",
        textAlign: "center",
        fontWeight: "600",
    },
    stepsContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    stepItem: {
        width: "100%",
        maxWidth: "300px",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        marginBottom: "20px",
    },
    stepItemHover: {
        transform: "translateY(-5px)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    },
    icon: {
        fontSize: "2.5em",
        marginBottom: "15px",
        color: "#2980b9",
    },
    validationSection: {
        padding: "20px",
        backgroundColor: "#f0f9f9",
        borderRadius: "10px",
    },
    validationText: {
        fontSize: "1.2em",
        color: "#34495e",
        marginBottom: "10px",
    },
    globalExpansionSection: {
        padding: "20px",
        backgroundColor: "#ecf9f9",
        borderRadius: "10px",
    },
    globalExpansionText: {
        fontSize: "1.2em",
        color: "#16a085",
        marginBottom: "10px",
    },
    sectionWithImage: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        alignItems: "center",
        marginBottom: "50px",
    },
    sectionWithImageReversed: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        flexDirection: "row-reverse",
        alignItems: "center",
        marginBottom: "50px",
    },
    sectionImage: {
        width: "100%",
        maxWidth: "400px",
        borderRadius: "10px",
    },
    costContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
    },
    costList: {
        width: "100%",
        maxWidth: "600px",
    },
    smallIcon: {
        marginRight: "10px",
        color: "#2980b9",
    },
    componentsContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
    },
    componentsList: {
        width: "100%",
        maxWidth: "600px",
    },
    additionalTipsSection: {
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
    },
    successSection: {
        padding: "20px",
        backgroundColor: "#f0f9f9",
        borderRadius: "10px",
    },
    earningsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
    },
    earningsList: {
        width: "100%",
        maxWidth: "600px",
    },
    bigIcon: {
        fontSize: "3em",
        color: "#16a085",
        marginBottom: "20px",
    },
};

