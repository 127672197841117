import React from "react";
import './About.css'

export default function About() {
    return (
        <div className="Career">

            <div class="super-card">
                <div class="employee-card">
                    <div class="circle-image">
                        <img src="assets/aboutus/rajkumar.jpeg" alt="SHER Team" />
                    </div>
                    <h3>Rajkumar Verma</h3>
                    <p>Founder & CEO</p>
                </div>
                <div class="employee-card">
                    <div class="circle-image">
                        <img src="assets/aboutus/cto.jpeg" alt="SHER Team" />
                    </div>
                    <h3>Alaka Singh</h3>
                    <p>Cofounder & CTO</p>
                </div>
                <div class="employee-card">
                    <div class="circle-image">
                        <img src="assets/aboutus/manager.jpeg" alt="SHER Team" />
                    </div>
                    <h3>Vinay Verma</h3>
                    <p>Market Research Manager</p>
                </div>
            </div>
        </div>
    );
}