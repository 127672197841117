import React, { useState } from 'react';
import Button from '@mui/material/Button';

const FloatingMessageBox = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleButtonClickGform = () => {
        // alert('Application is opening...');
        window.location.href = 'https://forms.gle/8oo65LEmvxaFdpSg8';
    };
    return (
        <div style={styles.container}>
            <div style={styles.icon} onClick={handleToggle}>
                💬
            </div>
            {isOpen && (

                <div style={styles.popup}>
                    <p>
                        Feel free to contact us.
                    </p>
                    <p>
                        📧 <a href="mailto:help@managelibs.com" style={styles.link}>help@managelibs.com</a>
                    </p>
                    <p>
                        📞 <a href="tel:+916388257447" style={styles.link}>+91 6388257447</a>
                    </p>
                    <p>
                        <Button variant="contained" onClick={handleButtonClickGform}>
                            Book 5 min conversession for free
                        </Button>
                    </p>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
    },
    icon: {
        backgroundColor: '#007bff',
        color: '#fff',
        padding: '15px',
        borderRadius: '5px',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
    },
    popup: {
        marginTop: '10px',
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
        textAlign: 'left',
    },
    link: {
        color: '#007bff', // Set link color to a visible shade
        textDecoration: 'none', // Remove underline
    },
};

export default FloatingMessageBox;
