import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Create this CSS file

const Navbar = () => {
    return (
        <nav className="navbar">
            <ul className=" sidebar">
                <li onClick={hideSidebar} className="sidebarLink">
                    <Link to=""><svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 96 960 960" width="26"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" /></svg></Link>
                </li>
                <li onClick={hideSidebar}>
                    <Link to="/">Home</Link>
                </li>
                <li onClick={hideSidebar}>
                    <Link to="/openlibraryguide">Open Library</Link>
                </li>
                <li onClick={hideSidebar}>
                    <Link to="/service">Services</Link>
                </li>
                <li onClick={hideSidebar}>
                    <Link to="/about">About us</Link>
                </li>
                <li onClick={hideSidebar}>
                    <Link to="/blog">Blog</Link>
                </li>
                <li onClick={hideSidebar}>
                    <Link to="/career">Career</Link>
                </li >
                <li onClick={hideSidebar}>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>

            <ul className="nav-links">
                <li >
                    <Link to="/"><img className="logo" src='/assets/OpenScienceLogo2.png' alt="managelibs logo" />
                    </Link>
                </li>
                <li className="hideOnMobile">
                    <Link to="/">Home</Link>
                </li>
                <li className="hideOnMobile">
                    <Link to="/openlibraryguide">Open Library</Link>
                </li>
                <li className="hideOnMobile">
                    <Link to="/service">Services</Link>
                </li>
                <li className="hideOnMobile">
                    <Link to="/about">About us</Link>
                </li>
                <li className="hideOnMobile">
                    <Link to="/blog">Blog</Link>
                </li>
                <li className="hideOnMobile">
                    <Link to="/career">Career</Link>
                </li>
                <li className="hideOnMobile">
                    <Link to="/contact">Contact</Link>
                </li>
                <li onClick={showSidebar} className="menu-button">
                    <Link className='menu' to=""><svg color='white' xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 96 960 960" width="26"><path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" /></svg></Link>
                </li>


            </ul>
        </nav>
    );
};



function showSidebar() {
    const sidebar = document.querySelector('.sidebar')
    sidebar.style.display = 'flex'
}
function hideSidebar() {
    const sidebar = document.querySelector('.sidebar')
    sidebar.style.display = 'none'
}


export default Navbar;
