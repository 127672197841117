import React, { useState } from 'react';
import { FaFireExtinguisher, FaExclamationTriangle } from 'react-icons/fa';

const SafetyFeaturesSection = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const safetyFeatures = [
        {
            icon: <FaFireExtinguisher />,
            title: 'Fire Safety',
            description: 'Fire alarms and extinguishers installed in key areas for maximum safety.',
        },
        {
            icon: <FaExclamationTriangle />,
            title: 'Emergency Button',
            description: 'Emergency button for quick door opening in critical situations.',
        },
    ];

    return (
        <section style={styles.additionalTipsSection}>
            <h2 style={styles.sectionTitle}>Safety & Emergency Features</h2>
            <div style={styles.safetyCards}>
                {safetyFeatures.map((feature, index) => (
                    <div
                        key={index}
                        style={styles.card}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={() => setHoveredIndex(index === hoveredIndex ? null : index)} // Toggle on click
                    >
                        <div style={styles.icon}>{feature.icon}</div>
                        <h3 style={styles.cardTitle}>{feature.title}</h3>
                        <p
                            style={{
                                ...styles.cardDescription,
                                maxHeight: hoveredIndex === index ? '100px' : '0', // Adjust height based on hover or tap
                                opacity: hoveredIndex === index ? 1 : 0,
                                transition: 'max-height 0.3s ease, opacity 0.3s ease',
                                overflow: 'hidden',
                            }}
                        >
                            {feature.description}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
};

const styles = {
    additionalTipsSection: {
        padding: '20px',
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
    },
    sectionTitle: {
        fontSize: '2.5em',
        color: '#2c3e50',
        marginBottom: '30px',
    },
    safetyCards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
        maxWidth: '800px',
        margin: '0 auto',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        width: '250px',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        cursor: 'pointer',
        position: 'relative',
    },
    icon: {
        fontSize: '2.5em',
        marginBottom: '10px',
        color: '#e74c3c',
    },
    cardTitle: {
        fontSize: '1.5em',
        color: '#34495e',
        marginBottom: '10px',
    },
    cardDescription: {
        fontSize: '1.1em',
        color: '#7f8c8d',
        lineHeight: '1.4em',
        maxHeight: '0',
        opacity: 0,
        overflow: 'hidden',
        transition: 'max-height 0.3s ease, opacity 0.3s ease',
    },
};

export default SafetyFeaturesSection;
